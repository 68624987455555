.modal-box{
    min-height: 100vh;
    font-family: 'Arimo', sans-serif;
    font-size: 16px;
}
/* .header{
    position: absolute;
    z-index: 9999;
} */
.d-header-label{
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    letter-spacing: 1.19077px;
    color: #454545;
}
.d-border-bottom{
    border-bottom: 2px solid #0f436e26;
}


div:not(.input-group) > input, div:not(.input-group) > button{
    border-radius: 3.6px !important;
}

.btn{
    font-size: 0.8rem;
    padding: 0.45rem 1rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    letter-spacing: 0.641667px;
}
.btn-continue{
    background: #05415D;
    border: 2px solid #05415D;
    color: #fff;
    box-shadow: 0px 1px 2px rgba(110, 61, 15, 0.228119);
}
.btn-back{
    border: 2px solid #05415D;
    font-weight: 600;
    background: #fff;
    color: #05415D;
}
.btn-cancel{
    border: #fff;
    font-weight: 600;
    background: #fff;
    color: #05415D;
}
.btn-check:checked+.btn {
    background-color: #05415D !important;
    border-color: #05415D !important;
    color: #fff !important;
    font-weight: 500 !important;
}
.red-error{
    color: #E71616;
}

.btn-continue-desable{
    border: 2px solid #C4C4C4;
    cursor: none;
    pointer-events: none;
    background-color: #C4C4C4;
}

.btn-back-desable{
    cursor: none;
    pointer-events: none;
    color: #C4C4C4;
    border-color: #C4C4C4;
}

.btn-cancel-desable{
    cursor: none;
    pointer-events: none;
    color: #C4C4C4;
}

ngb-datepicker{
    box-shadow: 0px 8px 31px -12px #454545;
}